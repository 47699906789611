import React, { useState, useEffect } from 'react'
import Router from 'next/router'
import Head from 'next/head'
import { ThemeProvider } from 'next-themes'
import { LazyMotion, domAnimation, AnimatePresence } from 'framer-motion'
import { install } from 'resize-observer'
import '../styles/tailwind.css'
import '../styles/app.css'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'

import { SiteContextProvider } from '@lib/context'
import { I18nContextProvider } from '@lib/i18n'

import { isBrowser, useScrollRestoration } from '@lib/helpers'
import { pageTransitionSpeed } from '@lib/animate'
import Cart from '@modules/shop/cart'
import { GoogleTagManager } from '@modules/shared/google-tag-manager'
import { FacebookPixel } from '@modules/shared/facebook-pixel'

const MyApp = ({ Component, pageProps, router }) => {
  const [isLoading, setLoading] = useState(false)
  if (isBrowser && !window.ResizeObserver) install()

  // Trigger our loading class
  useEffect(() => {
    if (isBrowser) {
      document.documentElement.classList.toggle('is-loading', isLoading)
    }
  }, [isLoading])

  // Setup Next router events
  useEffect(() => {
    Router.events.on('routeChangeStart', (url) => {
      // Bail if we're just changing a URL parameter
      if (
        url.indexOf('?') > -1 &&
        url.split('?')[0] === router.asPath.split('?')[0]
      )
        return

      // Otherwise, start loading
      setLoading(true)
    })

    Router.events.on('routeChangeComplete', () => {
      setTimeout(() => setLoading(false), pageTransitionSpeed) // accounts for page transition
    })

    Router.events.on('routeChangeError', () => {
      setLoading(false)
    })
  }, [])

  // Handle scroll position on history change
  useScrollRestoration(router, pageTransitionSpeed)

  // intelligently add focus states if keyboard is used
  const handleFirstTab = (event) => {
    if (event.keyCode === 9) {
      if (isBrowser) {
        document.body.classList.add('is-tabbing')
        window.removeEventListener('keydown', handleFirstTab)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleFirstTab)
    return () => {
      window.removeEventListener('keydown', handleFirstTab)
    }
  }, [])

  return (
    <ThemeProvider disableTransitionOnChange>
      <I18nContextProvider locale={router.locale} locales={['en', 'ja']}>
        <SiteContextProvider data={{ ...pageProps?.data?.site }}>
          <FacebookPixel>
            <GoogleTagManager>
              <LazyMotion features={domAnimation}>
                {isLoading && (
                  <Head>
                    <title>Loading...</title>
                  </Head>
                )}
                <AnimatePresence
                  exitBeforeEnter
                  onExitComplete={() => {
                    window.scrollTo(0, 0)
                    document.body.classList.remove('overflow-hidden')
                  }}
                >
                  <Component key={router.asPath.split('?')[0]} {...pageProps} />
                </AnimatePresence>

                <Cart data={{ ...pageProps?.data?.site }} />
              </LazyMotion>
            </GoogleTagManager>
          </FacebookPixel>
        </SiteContextProvider>
      </I18nContextProvider>
    </ThemeProvider>
  )
}

export default MyApp
