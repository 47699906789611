import React from 'react'
import Img from 'next/image'
import { useNextSanityImage } from 'next-sanity-image'
import { getSanityClient } from '../lib/sanity'
import imageUrlBuilder from '@sanity/image-url'
import cx from 'classnames'

const Photo = (props) => {
  const { photo, ...other } = props

  if (!photo) return null
  const imageProps = useNextSanityImage(getSanityClient(), photo)
  return (
    <Img
      {...imageProps}
      {...other}
      className={cx(other.className, { spin: photo.spin })}
    />
  )
}

const builder = imageUrlBuilder(getSanityClient())

export const imageUrlFor = (source) => {
  return builder.image(source)
}

export default Photo
