import React, { createContext, useContext } from 'react'
import i18n from 'simplest-i18n'
// Set context
const I18nContext = createContext({
  t: () => null,
})

const I18nContextProvider = ({ children, locale, locales = ['en', 'ja'] }) => {
  const t = i18n({
    locale,
    locales,
  })

  return <I18nContext.Provider value={{ t }}>{children}</I18nContext.Provider>
}

const useI18nContext = () => useContext(I18nContext)

export { I18nContextProvider, useI18nContext }
